import type { App } from 'vue'

export default {
    install(app: App) {
        app.config.globalProperties.$locale = Locale
        app.config.globalProperties.$auth = Auth
        app.config.globalProperties.$globalConfig = GlobalConfig
        app.config.globalProperties.$localeLinks = LocaleLinks
    },
}
