<script setup lang="ts">
import LabelElem from '@shared/components/Form/LabelElem.vue'
import Counter from '@shared/components/Form/Counter.vue'

defineProps<{
    label: string
    id: string
    name?: string
    type?: string
    required?: boolean
    placeholder?: string
    class?: string
    tip?: string
    maxlength?: number
}>()

const emit = defineEmits<{
    (e: 'blur'): void
}>()

const modelValue = defineModel()
</script>

<template>
    <div class="flex flex-col relative">
        <label-elem :id :tip :label :required />

        <input
            class="border border-border rounded-sm w-full h-12 px-3 max-w-96 font-sans"
            v-model="modelValue"
            @blur="emit('blur')"
            :type="type ? type : 'text'"
            :class
            :id
            :name
            :placeholder
            :required
            :maxlength
        />

        <counter v-if="maxlength" v-model="modelValue" :maxlength />
    </div>
</template>
