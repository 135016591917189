<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { computed } from 'vue'
import { trans } from 'laravel-vue-i18n'
import dispatchEvent from '@shared/modules/dispatchEvent'
import StarIcon from '@shared/components/Icons/StarIcon.vue'

const props = defineProps<{
    rate: number
    reviewsCount?: number | null
    product?: Product | null | undefined
}>()

const productUrl = computed<string | null>(() => {
    if (!props.product || !props.product.categories) {
        return null
    }

    if (props.product.categories.length === 0) {
        return null
    }

    const category = props.product.categories[0]

    return `/${category.slug}/${props.product.slug}#reviews`
})

function navigateToProduct() {
    if (!productUrl.value) {
        console.info('Product URL is not available')
        return
    }

    window.location.href = productUrl.value

    dispatchEvent('scrolledToReviews')
}
</script>

<template>
    <div
        @click.prevent="navigateToProduct"
        class="inline-flex items-center"
        :class="{
            'hover:scale-105 transition-transform cursor-pointer': productUrl,
        }"
    >
        <div class="flex items-center relative">
            <star-icon
                v-for="i in 5"
                :key="i"
                class="size-5 text-yellow-500"
                fill="currentColor"
                aria-hidden="true"
            ></star-icon>

            <div
                class="bg-white absolute inset-y-0 right-0 overflow-hidden pointer-events-none"
                :style="{
                    width: 100 - rate * 20 + '%',
                    mixBlendMode: 'color',
                }"
            ></div>
        </div>

        <span class="text-gray-500 ml-2">
            {{ rate }}
        </span>

        <span v-if="reviewsCount" class="mx-1 text-gray-400">&#x2022;</span>

        <span v-if="reviewsCount" class="text-gray-600">
            {{ trans('product.amount_of_reviews') }}: {{ reviewsCount }}
        </span>
    </div>
</template>
