<script setup lang="ts">
import { computed } from 'vue'

defineProps<{
    maxlength: number
}>()

const modelValue = defineModel()
const currLength = computed<number>(() => (modelValue.value as string).length)
</script>

<template>
    <small
        class="absolute bottom-1 right-1 z-10 bg-page rounded-md px-1"
        :class="currLength > maxlength ? 'text-red-700' : 'text-green-700'"
    >
        {{ currLength }}/{{ maxlength }}
    </small>
</template>
