import showToast from '@shared/modules/showToast'

export default (err: any, toastDuration?: number) => {
    console.error(err)

    if (err && err.response && err.response.data) {
        if (err.response.data.message) {
            showToast({
                text: err.response.data.message,
                success: false,
                duration: toastDuration,
            })
            return
        }

        if (err.response.data.exception) {
            showToast({
                text: err.response.data.exception,
                success: false,
                duration: toastDuration,
            })
            return
        }
    }

    showToast({
        text: 'Неизвестная ошибка',
        success: false,
        duration: toastDuration,
    })
}
