<script setup lang="ts">
import LabelElem from '@shared/components/Form/LabelElem.vue'
import Counter from '@shared/components/Form/Counter.vue'

defineProps<{
    label: string
    id: string
    name?: string
    required?: boolean
    placeholder?: string
    class?: string
    tip?: string
    maxlength?: number
}>()

const modelValue = defineModel()
</script>

<template>
    <div class="flex flex-col relative">
        <label-elem :id :tip :label :required />

        <textarea
            v-model="modelValue"
            class="border border-border rounded-sm w-full px-3 py-2 font-sans"
            :class
            :id
            :name
            :placeholder
            :required
            :maxlength
            rows="6"
        ></textarea>

        <counter v-if="maxlength" v-model="modelValue" :maxlength />
    </div>
</template>
