<script setup lang="ts">
import LabelElem from '@shared/components/Form/LabelElem.vue'

const emit = defineEmits<{
    (e: 'change'): void
}>()

defineProps<{
    label: string
    id: string
    name?: string
    required?: boolean
    placeholder?: string
    class?: string
    tip?: string
    disabled?: boolean
}>()

const modelValue = defineModel()
</script>

<template>
    <div class="flex flex-col">
        <label-elem :id :tip :label />

        <select
            class="bg-page border border-border rounded-sm w-full h-12 px-3 max-w-96 font-sans"
            v-model="modelValue"
            @change="emit('change')"
            :class
            :id
            :name
            :placeholder
            :required
            :disabled
        >
            <slot />
        </select>
    </div>
</template>
