<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import { computed } from 'vue'

const props = defineProps<{
    href?: string
    className?: string
    color?: string
    to?: RouteLocationRaw
    type?: 'submit' | 'button'
    disabled?: boolean
}>()

const styles = computed<string>(() => {
    let result = `${props.color} text-white px-5 py-2 rounded-lg transition-colors inline-flex items-center gap-4`

    if (props.className) {
        result = `${result} ${props.className}`
    }

    if (props.disabled) {
        result = `${result} opacity-50 cursor-not-allowed`
    }

    return result
})
</script>

<template>
    <a v-if="href" :href :class="styles">
        <slot />
    </a>

    <router-link v-else-if="to" :to :class="styles">
        <slot />
    </router-link>

    <button v-else-if="type" :type :class="styles" :disabled>
        <slot />
    </button>

    <div v-else :class="styles + ' cursor-pointer'">
        <slot />
    </div>
</template>
